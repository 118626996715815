import './Landing.css';
import 'react-multi-carousel/lib/styles.css';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { getFirestore, addDoc, collection, doc } from "firebase/firestore";

import Carousel from 'react-multi-carousel';

import {PHONE, EMAIL, VIEWPORT_LIMIT, OTHER_TABLE}  from '../libs/constants.js';

import { Logo } from '../components/Logo';
import TUXButton from '../components/TUXButton';
import TUXAppBarButton from '../components/TUXAppBarButton';
import TUXActionButton from '../components/TUXActionButton';
import Spacer from '../components/Spacer';
import { ContactUs }  from '../components/ContactUs';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import Other from './Other';

function ServiceAreaSection() {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [areaOfService, setAreaOfService] = useState('ja');
    const [otherOpen, setOtherOpen] = useState(false);
    const [anchorMenuEl, setAnchorMenuEl] = useState(null);
    const open = Boolean(anchorMenuEl);

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);

    const nav = useNavigate();

    const handleScheduleButtonClick = () => {
        if (areaOfService !== 'other') {
            nav(`/schedule/${areaOfService}`);
        } else {
            setOtherOpen(true);
        }
    };

    const handleMenuClick = (event) => {
        setAnchorMenuEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorMenuEl(null);
    };

    const addOther = (other) => {
        addDoc(collection(db, OTHER_TABLE),
            other
          ).then(()=> {
            setOtherOpen(false);
        });
    };

    useEffect(() => {
          function handleResize() {
              setViewPortWidth(window.innerWidth);
          }
          window.addEventListener('resize', handleResize)
        });
    const isSmallViewPort = (viewPortWidth < VIEWPORT_LIMIT);
    const isLoggedIn = (auth?.loggedIn && auth?.userInfo?.userId !== undefined);
    return (<div className="ServiceAreaSection">
                <div className="ServiceAreaSectionOverlay"></div>
                <div className="ServiceAreaMain">
                    <div className="ServiceAreaHeaderBar">

                     { !isSmallViewPort && (
                        <>
                        <div className="ServiceAreaHeaderBarLogoContainer">
                            <Logo /><span>TUXRydes</span>
                        </div>
                        {!isLoggedIn && (
                            <div className="ServiceAreaHeaderBarButtons">
                                <ContactUs />
                                <TUXButton text={"Login"} color={"white"} onClick={()=> nav('/signin')}/>
                            </div>
                            )}
                        {isLoggedIn && (
                            <div className="ServiceAreaHeaderBarButtonsLoggedIn">
                                <ContactUs />
                                <TUXAppBarButton color="white" text="My Trips" onClick={()=> nav('/rides')} />
                                <TUXAppBarButton color="white" text="My Profile" onClick={()=> nav('/profile')} />
                            </div>
                        )}
                        </>
                      )}
                     { isSmallViewPort && (
                        <div className="ServiceAreaHeaderBarSmallViewPort">
                            <Stack direction="row" alignItems={"center"}>
                                  {isLoggedIn && (
                                  <>
                                      <Button
                                        id="menu-button"
                                        aria-controls={open ? 'menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleMenuClick}
                                        style={{color: 'white'}}
                                      >
                                        <MenuIcon sx={{fontSize: 30}}/>
                                      </Button>
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorMenuEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                          'aria-labelledby': 'menu-button',
                                        }}
                                      >
                                        <MenuItem onClick={()=> {nav('/rides'); handleClose();}}>My Trips</MenuItem>
                                        <MenuItem onClick={()=> {nav('/profile'); handleClose();}}>My Profile</MenuItem>

                                      </Menu>
                                   </>
                                   )}
                                   {!isLoggedIn && (
                                    <>
                                        <TUXButton text={"Login"} color={"white"} onClick={()=> nav('/signin')}/>
                                    </>
                                   )}
                                  <div className="TUXRydeText">TUXRydes</div>
                            </Stack>
                        </div>
                     )}
                    </div>
                    <div className="ServiceAreaHeaderBody">
                        <div className="SeviceAreaSectionBlock">
                            <div className="SeviceAreaSectionTextHeader">THE SECURE RYDE COMPANY</div>
                            <div className="SeviceAreaSectionTextMain">We take the fear out of hailing a ryde ...<br/>and we do it with style.</div>
                        </div>
                        <div className="SeviceAreaSectionBlock">
                            <div className="ServiceAreaSelection">
                                <div className="ServiceAreaTextTitle">
                                    BOOK A RYDE
                                </div>
                                <div className="ServiceAreaText">
                                    Choose a service area and select Continue.
                                </div>
                                <div className="ServiceAreaRadio">
                                    <Stack spacing={1} direction="column">
                                        <div className="ServiceAreaSelectionButton">
                                            <TUXAppBarButton centered={true} bordered={(areaOfService !== 'ja')} text="Jamaica"  color={areaOfService === 'ja' ?"orange": "white"} onClick={() => setAreaOfService('ja')}/>
                                        </div>
                                        <div className="ServiceAreaSelectionButton">
                                            <TUXAppBarButton centered={true} bordered={(areaOfService !== 'sac')} text="Sacramento, CA" color={areaOfService === 'sac'?"orange": "white"} onClick={() => setAreaOfService('sac')}/>
                                        </div>
                                        <div className="ServiceAreaSelectionButton">
                                            <TUXAppBarButton centered={true} bordered={(areaOfService !== 'other')} text="Other" color={areaOfService === 'other'?"orange": "white"} onClick={() => setAreaOfService('other')}/>
                                        </div>
                                    </Stack>
                                </div>
                                <div className="ServiceAreaButtonContinue">
                                    <TUXActionButton flex={true} onClick={handleScheduleButtonClick}>Continue</TUXActionButton>
                                </div>
                            </div>
                        </div>
                     </div>
            </div>
            <Fragment>
                <Dialog open={otherOpen} onClose={()=> setOtherOpen(false)} >
                    <DialogContent>
                        <Other sendRequest={addOther}/>
                    </DialogContent>
                </Dialog>
            </Fragment>
        </div>);
}

function InfoCarousel() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 800 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    return (
        <div className="InfoCarousel">
            <div className="InfoCarouselImageOne"></div>
            <div className="InfoCarouselText">
                <div className="CarouselHighlight">Experience a <span className="OrangeText">quality</span> Ryde! Arrive in style.</div>
                <Carousel responsive={responsive} autoPlay={true} showDots={true} arrows={false} infinite={true} autoPlaySpeed={7000}>
                    <div className="SectionDiv">
                        <h2 className="OrangeText">Professional Drivers</h2>
                        <p>Our drivers are hand-selected for their
                           professionalism, courtesy and skill. And our drivers are well-
                           dressed to ensure a premium experience.</p>
                    </div>
                    <div className="SectionDiv">
                       <h2 className="OrangeText">Thorough Background Checks</h2>
                       <p>Our United States drivers
                       undergo thorough background checks conducted by the
                       Department of Justice and the FBI. Our Jamaican drivers undergo
                       background checks by the Jamaican Ministry of Justice.</p>
                    </div>
                    <div className="SectionDiv">
                        <h2 className="OrangeText">Security</h2>
                        <p>We give preference to hiring military-trained drivers.
                       When you choose our Secure service, we provide you with an
                       armed driver. Safety isn’t just our goal; it’s what we do.</p>
                    </div>
                </Carousel>
            </div>
        </div>);
}

function InfoSection(props) {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 800 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    return (
        <div className={props.borders ? "InfoCarousel" : "InfoCarouselNoBorders"}>
            <div className={props.imageClass}></div>
            <div className="InfoCarouselText">
                <Carousel responsive={responsive} autoPlay={true} showDots={true} arrows={false} infinite={true} autoPlaySpeed={7000}>
                    {props.children}
                </Carousel>
            </div>
        </div>);
}

function SafeSection() {
    return(<InfoSection imageClass={"InfoCarouselImageTwo"}>
            <div className="SectionDiv">
                <h2 className="OrangeText">Safety Measures</h2>
                <p>TUXRydes uses real-time monitoring and emergency assistance to
                   ensure you arrive safely at your destination with peace of mind.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">Vehicle Tracking</h2>
                <p> We don’t rely on phones that can be stolen or
                   turned off. All vehicles are equipped with advanced GPS tracking
                   systems to monitor routes and ensure safety.</p>
            </div>
            <div className="SectionDiv">
               <h2 className="OrangeText">Real-time Monitoring</h2>
               <p>Our in-cabin cameras and real-time
               surveillance measures ensure that you - and the driver - are always
               safe inside the vehicle.</p>
            </div>
            <div className="SectionDiv">
               <h2 className="OrangeText">Emergency Assistance</h2>
               <p>Immediate assistance is available in case
               of an emergency during your ryde.</p>
            </div>
            <div className="SectionDiv">
               <h2 className="OrangeText">Tactical Response Team</h2>
               <p>Our dedicated tactical response team is
               on standby to handle any security issues promptly and
               professionally.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">Insurance</h2>
                <p>All rydes are covered by comprehensive insurance for
               added peace of mind.</p>
            </div>
        </InfoSection>);
}

function QualitySection() {
    return(<InfoSection imageClass={"InfoCarouselImageThree"} borders={true}>
            <div className="SectionDiv">
                <h2 className="OrangeText">Quality and Comfort</h2>
                <p>Forget your troubles while riding in a luxury vehicle with all the creature
                   comforts.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">Luxury Vehicles</h2>
                <p>Enjoy rydes in late model, premium vehicles
               like Mercedes Benz, Lexus, and BMW. Our vehicles are
               maintained to the highest standards and are clean inside and out.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">Comfort</h2>
                <p>Each ryde offers the utmost comfort with features like
               climate control, leather seating, and advanced infotainment
               systems.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">Punctuality</h2>
                <p>We value your time, so we place an emphasis on
               being punctual for both pick-ups and drop-offs.</p>
            </div>
        </InfoSection>);
}

function SupportSection() {
    return(<InfoSection imageClass={"InfoCarouselImageFour"}>
            <div className="SectionDiv">
                <h2 className="OrangeText">Convenience and Support</h2>
                <p>We make booking easy and are always ready to help you with any
                   concerns you may have.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">Convenient Booking</h2>
                <p>Our intuitive app makes booking a ryde
                   quick and easy. And we remember your preferences, so your next
                   booking is even easier.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">Secure Payment Options</h2>
                <p>We offer multiple encrypted and secure
                   payment methods to protect your financial information.</p>
            </div>
            <div className="SectionDiv">
                <h2 className="OrangeText">24/7 Customer Support</h2>
                <p>Don’t want to use our app? Have an
                   issue? No problem. Our dedicated customer support team is always
                   available to help you book rydes and address your needs or
                   concerns around the clock.</p>
            </div>
        </InfoSection>);
}

function SafeCarousel() {
    const [selectIndex, setSelectedIndex] = useState(1);

    let firstItemClassName = "";
    let secondItemClassName = "";
    let thirdItemClassName = "";
    if (selectIndex === 1) {
        firstItemClassName = "SelectedSafeCarousel";
        secondItemClassName = "UnselectedBigSafeCarousel";
        thirdItemClassName = "UnselectedSmallSafeCarousel";
    } else if (selectIndex === 2) {
        firstItemClassName = "UnselectedBigSafeCarousel";
        secondItemClassName = "SelectedSafeCarousel";
        thirdItemClassName = "UnselectedBigSafeCarousel";
    } else if (selectIndex === 3) {
        firstItemClassName = "UnselectedSmallSafeCarousel";
        secondItemClassName = "UnselectedBigSafeCarousel";
        thirdItemClassName = "SelectedSafeCarousel";
    }
    return (
        <div className="SafeCarousel">
            <div className="SafeCarouselTitle">
                <div onClick={()=>setSelectedIndex(1)} className={firstItemClassName}>Safety Measures</div>
                <div onClick={()=>setSelectedIndex(2)} className={secondItemClassName}>Quality and Comfort</div>
                <div onClick={()=>setSelectedIndex(3)} className={thirdItemClassName}>Convenience and Support</div>
            </div>
            <div className="SafeCarouselInfo">
                {selectIndex === 1 && (<div className="SafeCarouselBlock">
                    <h3 className="OrangeText">Safety Measures</h3>
                    <p>TUXRydes uses real-time monitoring and emergency assistance to
                       ensure you arrive safely at your destination with peace of mind.</p>
                    <p><span className="OrangeText">Vehicle Tracking:</span> We don’t rely on phones that can be stolen or
                       turned off. All vehicles are equipped with advanced GPS tracking
                       systems to monitor routes and ensure safety.</p>
                    <p><span className="OrangeText">Real-time Monitoring:</span> Our in-cabin cameras and real-time
                       surveillance measures ensure that you - and the driver - are always
                       safe inside the vehicle.</p>
                    <p><span className="OrangeText">Emergency Assistance:</span> Immediate assistance is available in case
                       of an emergency during your ryde.</p>
                    <p><span className="OrangeText">Tactical Response Team:</span> Our dedicated tactical response team is
                       on standby to handle any security issues promptly and
                       professionally.</p>
                    <p><span className="OrangeText">Insurance:</span> All rydes are covered by comprehensive insurance for
                       added peace of mind.</p>
                </div>)}
                {selectIndex === 2 && (<div className="SafeCarouselBlock">
                    <h3 className="OrangeText">Quality and Comfort</h3>
                    <p>Forget your troubles while riding in a luxury vehicle with all the creature
                       comforts.</p>
                    <p><span className="OrangeText">Luxury Vehicles:</span> Enjoy rydes in late model, premium vehicles
                       like Mercedes Benz, Lexus, and BMW. Our vehicles are
                       maintained to the highest standards and are clean inside and out.</p>
                    <p><span className="OrangeText">Comfort:</span> Each ryde offers the utmost comfort with features like
                       climate control, leather seating, and advanced infotainment
                       systems.</p>
                    <p><span className="OrangeText">Punctuality:</span> We value your time, so we place an emphasis on
                       being punctual for both pick-ups and drop-offs.</p>
                </div>)}
                {selectIndex === 3 && (<div className="SafeCarouselBlock">
                    <h3 className="OrangeText">Convenience and Support</h3>
                    <p>We make booking easy and are always ready to help you with any
                       concerns you may have.</p>
                    <p><span className="OrangeText">Convenient Booking:</span> Our intuitive app makes booking a ryde
                       quick and easy. And we remember your preferences, so your next
                       booking is even easier.</p>
                    <p><span className="OrangeText">Secure Payment Options:</span> We offer multiple encrypted and secure
                       payment methods to protect your financial information.</p>
                    <p><span className="OrangeText">24/7 Customer Support:</span> Don’t want to use our app? Have an
                       issue? No problem. Our dedicated customer support team is always
                       available to help you book rydes and address your needs or
                       concerns around the clock.</p>
                </div>)}
            </div>
        </div>);
}

function Footer() {
    return (
        <div className="Footer">
            <div className="FooterOverlay"></div>
            <div className="FooterContactUsBar">
                <div className="FooterContactUsBlock">
                    <div>Email</div><div>{EMAIL}</div>
                </div>
                <div className="FooterContactUsBlock">
                    <div>Phone</div><div>{PHONE}</div>
                </div>

                <div className="FooterContactUsBlock">
                    <div></div>
                </div>
            </div>
            <div className="FooterDocsBar">
                <div className="FooterDocsBlock">© 2024 TUXRydes All rights reserved</div>
                <div className="FooterDocsBlock"><a rel="noopener noreferrer" href={"/Terms_and_Conditions.pdf"} target="_blank">Terms and Conditions</a></div>
                <div className="FooterDocsBlock"><a rel="noopener noreferrer" href={"/Tux_Privacy_Policy.pdf"} target="_blank">Privacy Policy</a></div>
            </div>
            <div className="FooterTitle">TUXRydes</div>
        </div>);
}

function FaqSection(props) {
    const [showBlock, setShowBlock] = useState(false);

    return (<div className="FaqSection">
                <div className="FaqSectionTitle">
                    <span>{props.title}</span>
                    <div className="FaqSectionTitleButton">
                        {!showBlock && (<Button onClick={() => setShowBlock(true)} style={{color: '#F85804'}}><AddIcon /></Button>)}
                        {showBlock && (<Button onClick={() => setShowBlock(false)} style={{color: '#F85804'}}><RemoveIcon /></Button>)}
                    </div>
                </div>
                { showBlock &&(<div className="FaqSectionBlock">{props.children}</div>) }
            </div>);
}

function Faq() {
    return (
        <div className="Faq">
            <div className="FaqTitle">Frequently asked <span className="OrangeText">questions</span></div>
            <div className="FaqSections">
                <FaqSection title={"How do I book a ryde?"}>
                    <p>Booking a ryde is easy. Simply select a service area, and we then guide you through
                    the process in a few easy steps. First, tell us where you want to go and when you want
                    your journey to start. Because we are a secure driving service, we require at least two
                    (2) hours notice before you want to be picked up. This gives time to select and
                    appropriate driver for your needs and service level.</p>

                    <p>Next, we ask you to select a service level, which allows you to choose an armed or
                    unarmed driver.</p>

                    <p>Next, we ask for your name and contact information. If you don’t have an account
                    with us, you can create one at this point in the process. If you have an account - and
                    aren’t already signed in - you can sign in.</p>
                    <p>Finally, we use a secure payment system (Stripe) to take your payment information.</p>

                    <p>When you complete the reservation, we send you a confirmation in your email. If you
                    have any issues or need to change something, just give us a call at {PHONE}.</p>
                </FaqSection>
                <FaqSection title={"What areas does TUXRydes operate in?"}>
                    <p>TUXRydes is open for business in the Sacramento, California area and the island of
                       Jamaica. We are actively pursuing expansion to other areas, so if you would like to see
                       TUXRydes in another part of the country or world, let us know. We will take your
                       suggestion(s) into serious consideration.</p>
                </FaqSection>
                <FaqSection title={"How does TUXRydes ensure passenger safety?"}>
                    <p>TUXRydes is not a typical ride-hailing service. TUXRydes uses many ways to help
                       ensure your safety, and we are adding new ways continuously. In the US, we run full
                       background checks with the FBI and US Department of Justice. In Jamaica, we
                       perform similar background checks with the Jamaican Ministry of Justice.</p>

                    <p>But that is not enough, we also interview all of our drivers personally. Many of our
                       drivers are militarily trained, and the others have security experience. You can request
                       an armed driver using our Secure service level.</p>
                    <p>Furthermore, in each of our service areas, we monitor the vehicles constantly and we
                       have a tactical response team at the ready to support you in the event of an emergency
                       (that the driver cannot handle).</p>
                </FaqSection>
                <FaqSection title={"What is the difference between the Safe and Secure service levels?"}>
                    <p>Our Safe service level offers you a quality ryde with one of our vetted, professional
                       drivers in a luxury car; like Mercedes, Lexus or BMW. Our cars are clean and well-
                       maintained, so you will arrive at your destination safely and in comfort.</p>
                     <p>Our Secure service level takes safety up a notch. With our Secure service, you receive
                        a vetted, professional, armed driver in addition to being transported in a clean, well-
                        maintained vehicle.</p>
                </FaqSection>
                <FaqSection title={"How does TUXRydes select its drivers?"}>
                    <p>TUXRydes interviews drivers with security service or military backgrounds. We
                       perform background checks (FBI and Department of Justice), and interview each candidate.</p>
                </FaqSection>
                <FaqSection title={"Are my payments secure?"}>
                    <p>We use Stripe to processes payments. Stripe is the largest private financial tech
                       company and the second largest payment processing company in the world. Stripe
                       processed over $1 trillion in payments volume in 2023. Stripe is a trustworthy
                       payment processor that encrypts your information to keep it out of the hands of bad
                       actors.</p>
                </FaqSection>
            </div>
        </div>);
}

function Landing() {
    return (<div>
        <div className="Landing">
            <ServiceAreaSection />
            <Spacer />
            <InfoCarousel />
            <Spacer />
            <SafeSection />
            <Spacer />
            <QualitySection />
            <Spacer />
            <SupportSection />
            <Spacer />
            <Faq />
            <Spacer />
            <Footer />
        </div>
    </div>);
}

export default Landing;