import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material';

export const IS_PROD = true;
export const SAFE = 'safe';
export const SECURE = 'secure';
export const ULTRA_SECURE = 'ultra-secure';
export const HOURLY_BASE = 70;
export const BOOKED_RIDES_TABLE = (IS_PROD) ?'prod/tux/BookedRides':'dev/tux/BookedRides';
export const RYDERS_TABLE = (IS_PROD) ?'prod/tux/ryders':'dev/tux/ryders';
export const DRIVERS_TABLE = (IS_PROD) ? 'prod/tux/drivers' : 'dev/tux/drivers';
export const CONFIRMATION_TABLE = (IS_PROD) ?'prod/tux/ConfirmationSequence' : 'dev/tux/ConfirmationSequence';
export const OTHER_TABLE = (IS_PROD) ?'prod/tux/other' : 'dev/tux/other';
export const MAX_PASSENGERS = 6;
export const MAX_BAGS = 12;
export const MAX_NON_STANDARD_BAGS = 4;

export const COOKIE_ID = 'ppCc';
export const COOKIE_MAX_AGE = 24 * 60 * 60;

export const STRIPE_PK_TEST = 'pk_test_51P4kZbB4GksnP0StidxwyNmVmKS3kcDj5loeKcdC15NkmUbeKZYUWHrxJq8yvEplmm3gArePI5t1GLF82j1kdY7X00BMyl5VRv';
export const STRIPE_PK_LIVE ='pk_live_51P4kZbB4GksnP0StKJAIj2wBGHJxOZbRJE2DUwc6m8hHhyZrBVFRiFYZeihyJW5Owq8V4CNtL9Gz6MLEqdWSdEKJ00ZoSpj8MA';
export const STRIPE_INTENT_DEV_URL = 'https://us-central1-tuxdev-a23d9.cloudfunctions.net/intentAPI/intentsDev';
export const STRIPE_CUSTOMERS_DEV_URL = 'https://us-central1-tuxdev-a23d9.cloudfunctions.net/intentAPI/customersDev';
export const STRIPE_INTENT_URL = 'https://us-central1-tuxdev-a23d9.cloudfunctions.net/intentAPI/intents';
export const STRIPE_CUSTOMERS_URL = 'https://us-central1-tuxdev-a23d9.cloudfunctions.net/intentAPI/customers';
export const VIEWPORT_LIMIT = 800;

export const PHONE = '+1 (916) 345-4777';
export const EMAIL = 'support@tuxrydes.com';
export const FAILED_PAYMENT_PROD = 'Unsuccessful payment, please try again ...';

export const TEXT_FIELD_THEME = createTheme({
      components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '30px',
                    backgroundColor: '#F5F5F5',
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: '#F85804',
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: '#F85804',
                    },
                    [`.${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: '#E0E0E0',
                    },
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    ['&.Mui-focused']: {
                      color: '#F85804',
                    },
                }
            }
        }
      },
    });
