import './App.css';
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { APIProvider } from '@vis.gl/react-google-maps';
import { CookiesProvider } from 'react-cookie';

import BookRide from './pages/BookRide';
import ConfirmPayment from './pages/ConfirmPayment';
import Profile from './pages/Profile'
import Layout from './pages/Layout';
import Rides from './pages/Rides';
import SignIn from './pages/SignIn';
import Signup from './pages/Signup';
import Landing from './pages/Landing';
import ResetPassword from './pages/ResetPassword';
import { AuthContext }  from './providers/AuthContext.js';
import { FirebaseContext } from './providers/FirebaseContext.js';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBD__09hW4pjR2saUqZAyH9nyNlrPcwYAQ",
  authDomain: "tuxdev-a23d9.firebaseapp.com",
  projectId: "tuxdev-a23d9",
  storageBucket: "tuxdev-a23d9.appspot.com",
  messagingSenderId: "392547723678",
  appId: "1:392547723678:web:b3d0623597975b7c580e8f",
  measurementId: "G-JJG2GED2EK"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const GOOGLE_MAPS_API_KEY = 'AIzaSyBD__09hW4pjR2saUqZAyH9nyNlrPcwYAQ';

function App() {
  const [auth, setAuth] = useState(null);
  const [firebaseApp, setFirebaseApp] = useState(app);

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AuthContext.Provider value={{auth, setAuth}}>
                <FirebaseContext.Provider value={{firebaseApp, setFirebaseApp}}>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Landing />} />
                        <Route path="/schedule/:serviceArea" element={<BookRide />} />
                        <Route path="/schedule" element={<BookRide />} />
                        <Route path="/confirmpayment/:confirmationCode" element={<ConfirmPayment />}/>
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/rides" element={<Rides />} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/resetpassword" element={<ResetPassword />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </FirebaseContext.Provider>
              </AuthContext.Provider>
            </LocalizationProvider>
        </APIProvider>
    </CookiesProvider>
  );
}

export default App;
