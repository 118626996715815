import { EXCURSION, HOURLY_BASE, SAFE, SECURE, ULTRA_SAFE } from './constants.js';
import dayjs from 'dayjs';

export function distanceToTime(distance) {
    const time = distance / 35;
    const wholePartNumber = Math.floor(time);
    const fractionPartNumber = time - wholePartNumber;
    if (fractionPartNumber < 0.25) {
        return wholePartNumber;
    } else if (fractionPartNumber < 0.5) {
        return wholePartNumber + 0.25;
    } else if (fractionPartNumber < 0.75) {
        return wholePartNumber + 0.50;
    } else if (fractionPartNumber >= 0.75) {
        return wholePartNumber + 0.75;
    } else if (wholePartNumber === 0) {
        return 0.25;
    } else {
        return wholePartNumber;
    }
}

const roundPrice = (price) => {
    return Math.round(price * 100) / 100;
};

export function allDayRate(props) {
    if (props.areaOfService === 'ja') {
        if (props.serviceLevel === SAFE) {
            return 800;
        } else if (props.serviceLevel === SECURE) {
            return 1600;
        }
    } else if (props.areaOfService === 'sac') {
        if (props.serviceLevel === SAFE) {
            return 1200;
        } else if (props.serviceLevel === SECURE) {
            return 1800;
        }
    }
}

export function hourlyRate(props) {
    if (props.areaOfService === 'ja') {
        if (props.serviceLevel === SAFE) {
            return 200;
        } else if (props.serviceLevel === SECURE) {
            return 300;
        }
    } else if (props.areaOfService === 'sac') {
        if (props.serviceLevel === SAFE) {
            return 200;
        } else if (props.serviceLevel === SECURE) {
            return 350;
        }
    }
}

const discountPeriods = [
    {
        startDate: '2024/06/29',
        endDate: '2024/07/08',
        discount: 0.80,
        minCost: 40,
    }
];

function discount(totalCost, props) {
    const today = dayjs();
    for (const discountPeriod of discountPeriods ) {
        const startDate = dayjs(discountPeriod.startDate);
        const endDate = dayjs(discountPeriod.endDate);
        if (today.isAfter(startDate) && today.isBefore(endDate)) {
            const discountedCost =  totalCost * discountPeriod.discount;
            return (discountPeriod?.minCost === undefined || discountedCost > discountPeriod.minCost) ? discountedCost: discountPeriod.minCost;
        }
    }
    return totalCost;
}

export function calculatePrice(props) {
    let totalCost = 0;
    for (const rideDataItem of props.rideData) {
        if (rideDataItem.allDay) {
            totalCost = totalCost + allDayRate({areaOfService: props.areaOfService, serviceLevel: props.serviceLevel});
        } else {
            if (!rideDataItem.estimatedDuration) {
                continue;
            }
            const hours = rideDataItem.estimatedDuration.value / (60 * 60);
            totalCost = totalCost + hourlyRate({areaOfService: props.areaOfService, serviceLevel: props.serviceLevel}) * hours;
        }
    }
    totalCost = discount(totalCost, props);
    return roundPrice(totalCost);

}