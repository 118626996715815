import { query, collection, where, getDoc, getDocs, doc } from "firebase/firestore";
import { SAFE, SECURE, ULTRA_SECURE, DRIVERS_TABLE, RYDERS_TABLE } from './constants';

export async function  getUserInfo(db, userId) {
    const ryderDoc = await getDocs(query(collection(db, RYDERS_TABLE), where('userId', '==', userId)));
    if (ryderDoc.docs.length === 1) {
        const ryder = ryderDoc.docs[0].data();
        return {userId: userId, id: ryderDoc.docs[0].id, firstName: ryder.firstName, lastName: ryder.lastName, phoneNumber: ryder.phoneNumber, email: ryder.email };
    } else if (ryderDoc.docs.length > 1) {
        throw new Error('Invalid user');
    }
    return null;
}

export async function  userExists(db, email) {
    const ryderDoc = await getDocs(query(collection(db, RYDERS_TABLE), where('email', '==', email)));
    if (ryderDoc.docs.length === 0) {
        return false;
    }
    return true;
}

export async function getDriverId(db) {
    const driversDoc = await getDocs(collection(db, DRIVERS_TABLE));
    if (driversDoc.docs.length > 0) {
        const driver = driversDoc.docs[0];
        return driver.id;
    }
    return null;
}

export async function getDriverInfo(db, driverId) {
    const driverDoc = await getDoc(doc(db, DRIVERS_TABLE, driverId));
    if (driverDoc) {
        const driver = driverDoc.data();
        return {firstName: driver.firstName, lastName: driver.lastName, phoneNumber: driver.phoneNumber, email: driver.email };
    }
    return null;
}

export function getRideStatus(ride) {
    if (!ride.legs) {
        return 'UNKNOWN';
    }
    if (ride.status === 'CANCELED') {
        return 'CANCELED';
    }
    const nowDateTime = new Date().getTime();
    let legNotStarted = ride.legs.length;
    for (const leg of ride.legs) {
        const pickUpDateTime = new Date(leg.pickUpDateTime).getTime();
        if (pickUpDateTime < nowDateTime) {
            legNotStarted--;
        }
    }
    if (legNotStarted === ride.legs.length) {
        return 'NOT STARTED';
    } else if (legNotStarted === 0) {
        return 'COMPLETED';
    } else {
        return 'IN PROGRESS';
    }
}

export function getServiceLevel(serviceLevel) {
    if (serviceLevel === SAFE) {
        return 'Safe';
    } else if (serviceLevel === SECURE) {
        return 'Secure';
    } else if (serviceLevel === ULTRA_SECURE) {
        return 'Ultra-Secure';
    } else {
        return serviceLevel;
    }
}

export function setUpPhoneNumber(old, value) {
    if ((old === null || old === '') && value.length >= 10) {
        return `${value.replace('+', '+1')}`;
    }
    return value;
}

export function printDate(dateString) {
   const date =  new Date(dateString);
   if (isNaN(date)) {
    return 'N/A';
   }
   return date.toLocaleString('en-US', {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true});
}