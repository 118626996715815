import './StripePayment.css';
import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Button from '@mui/material/Button';
import { FAILED_PAYMENT_PROD, STRIPE_PK_LIVE, STRIPE_PK_TEST, STRIPE_CUSTOMERS_URL, STRIPE_INTENT_URL, STRIPE_CUSTOMERS_DEV_URL, STRIPE_INTENT_DEV_URL, IS_PROD } from '../libs/constants.js';

import TUXActionButton from '../components/TUXActionButton';

function CheckoutForm (props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const handleSubmit = async (event) => {
        if (props?.presubmit) {
            props.presubmit();
        }
        setProcessing(true);
        event.preventDefault();

        if (!stripe || !elements) {
           // Stripe.js hasn't yet loaded.
           // Make sure to disable form submission until Stripe.js has loaded.
           if (props?.postsubmit) {
               props.postsubmit();
           }
           return;
        }

        const {error: submitError} = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
            setProcessing(false);
            if (props?.postsubmit) {
                props.postsubmit();
            }
            return;
        }

        try {
            const customerRes = await fetch(IS_PROD ? STRIPE_CUSTOMERS_URL : STRIPE_CUSTOMERS_DEV_URL, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({email: props.email, payment_method: 'card'})
            });

            const {id: id} = await customerRes.json();

            const intentRes = await fetch(IS_PROD ? STRIPE_INTENT_URL : STRIPE_INTENT_DEV_URL, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body:  JSON.stringify({amount: props.stripeAmount, customer: id, currency: 'usd', payment_method_type: 'card'})//JSON.stringify({amount: stripeAmount, currency: 'usd', customer: id, payment_method: 'card'})
            });

            const {client_secret: clientSecret} = await intentRes.json();

            const confirm = await stripe.confirmPayment({
              elements,
              clientSecret,
              redirect: 'if_required',
              confirmParams: {
                return_url: `${window.location.origin}/confirmpayment/${props.confirmationCode}`,
              },
            });

            if (confirm.error !== undefined) {
                if (IS_PROD) {
                    setErrorMessage(FAILED_PAYMENT_PROD);
                } else {
                    setErrorMessage(confirm.error.message);
                }
                setProcessing(false);
                if (props?.postsubmit) {
                    props.postsubmit();
                }
            } else {
                props.paymentSubmitted(confirm);
            }
        } catch (error) {
             if (IS_PROD) {
                setErrorMessage(FAILED_PAYMENT_PROD);
             } else {
                setErrorMessage(error.message);
            }
            setProcessing(false);
            if (props?.postsubmit) {
                props.postsubmit();
            }
            return;
        }

    };
    const handleBack = () => {
        props.goBack();
    };
    const paymentElementOptions = {
        layout: {
            type: 'accordion',
       },
    }
    const isDisabled = (!stripe || !elements || !props.valid() || props.price === 0 || processing);
    return (<form onSubmit={handleSubmit}>
            {errorMessage && (<div className="ErrorMessage">{errorMessage}</div>)}
            <PaymentElement options={paymentElementOptions}/>
            <div className="PaymentButtons">
                <div className={isDisabled ? "PaymentButtonDisabled": "PaymentButton"}>
                    <button variant="contained" type="submit" disabled={isDisabled}>Pay: {priceFormatter.format(props.stripeAmount / 100)}</button>
                </div>
            </div>
       </form>);
}

function StripePayment(props) {
    const stripePromise = loadStripe(props.live ? STRIPE_PK_LIVE : STRIPE_PK_TEST);
    const stripeAmount = Math.round(props.price * 100);
    const options = {mode: 'payment', amount: (props.price > 0 ? stripeAmount: 100), currency: 'usd',
            appearance: {theme: 'flat'}};
    return (
        <div className="StripePayment">
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm email={props.email} stripeAmount={stripeAmount} valid={props.valid} confirmationCode={props.confirmationCode} paymentSubmitted={props.paymentSubmitted} presubmit={props.presubmit} postsubmit={props.postsubmit}/>
            </Elements>
        </div>);
}

export default StripePayment;
