import './Signup.css';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';

import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 

import { getAuth, signInWithPopup, createUserWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";

import { TEXT_FIELD_THEME, RYDERS_TABLE, COOKIE_ID, COOKIE_MAX_AGE, VIEWPORT_LIMIT } from '../libs/constants.js';
import { setUpPhoneNumber, userExists } from '../libs/utils.js';

import MuiPhoneNumber from 'mui-phone-number';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import { LogoTextLink } from '../components/Logo';
import TUXActionButton from '../components/TUXActionButton';
import TUXTextField from '../components/TUXTextField';
import Spacer, { SpacerSmall } from '../components/Spacer';

const Mode = Object.freeze({
    SIGNUP: 0,
    APPLE: 1,
    GOGGLE: 2,
    EMAIL: 3,
    INFO: 4,
});

function Signup(props) {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [mode, setMode] = useState(Mode.SIGNUP);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [processing, setProcessing] = useState(false);
    const [cookies, setCookies] = useCookies([COOKIE_ID]);

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }; 

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    
     const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const [error, setError] = useState(null);

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const authFromApp = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();

    const signUpUsingEmail = () => {
        setMode(Mode.EMAIL);
    };

    const signUpUsingGoogle = () =>{
        setMode(Mode.GOOGLE);
    }

    const onFirstNameChange = (event: ChangeEvent) => {
       setFirstName(event.target.value);
    };

    const onLastNameChange = (event: ChangeEvent) => {
       setLastName(event.target.value);
    };

    const onPhoneNumberChange = (value) => {
       let newPhoneNumber = setUpPhoneNumber(phoneNumber, value);
       setPhoneNumber(newPhoneNumber);
    };

    const onEmailChange = (event: ChangeEvent) => {
       setEmail(event.target.value);
    };

    const onPasswordChange = (event: ChangeEvent) => {
       setPassword(event.target.value);
    };

    const onConfirmPasswordChange = (event: ChangeEvent) => {
       setConfirmPassword(event.target.value);
    };

    const validUserInfo = () => {
        if (firstName === '') {
            setError('First name is empty.');
            return false;
        }
        if (lastName === '') {
            setError('Last name is empty.');
            return false;
        }
        if (lastName === '') {
            setError('Phone number is empty.');
            return false;
        }
        return true;
    };

    const userInfoSet = () => {
        return  !(firstName === '' || lastName === '' || phoneNumber === '');
    };

    const emailSignUpInfoSet = () => {
        return  !(email === null || password === null || confirmPassword === null) &&
        !(email === '' || password === '' || confirmPassword === '');
    };

    const onSignUpEmailPassword = () => {
        if (email === null || email.length < 5 || !email.includes('@') || !email.includes('.')) {
            setError('Invalid email address');
            return;
        }
        if (password === null || password.length < 8 || password.length >  12) {
            setError('Password length needs to be 8 - 12 characters.');
            return;
        }
        if ( password !== confirmPassword ) {
            setError('Password does not match.');
            return;
        }
        if (!validUserInfo()) {
            return;
        }
        setProcessing(true);
        createUserWithEmailAndPassword(authFromApp, email, password)
          .then((result) => {
            // Signed up
            return result.user;
          })
          .then((user) => {

            addUserInfo(user);
          }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message.replace('Firebase:', '');
            setError(errorMessage);
            setProcessing(false);
          });
    };

    const onSignUpGoogle = () => {
        if (!validUserInfo()) {
            return;
        }
        setProcessing(true);
        signInWithPopup(authFromApp, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            return result.user;
          }).then((user) => {
           const db = getFirestore(firebaseApp);
           userExists(db, user.email).then((exists) => {
                if (exists) {
                    setError('Email already registered!');
                    setProcessing(false);
                    return;
                }
                addUserInfo(user);
            });

          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message.replace('Firebase:', '');
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            setError(errorMessage);
            setProcessing(false);
          });

    };

    const addUserInfo = (user) => {
        const db = getFirestore(firebaseApp);
        addDoc(collection(db, RYDERS_TABLE),
            {
              firstName: firstName,
              lastName: lastName,
              phoneNumber: phoneNumber,
              email: user.email,
              userId: user.uid,
            }
         ).then(()=> {
            const userInfo = {userId: user.uid, firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, email: user.email};
            setAuth({loggedIn: true, userInfo: userInfo});
            /*user.getIdToken().then((idToken) => {
                authFromApp.createSessionCookie(idToken,
                    { expiresIn: COOKIE_MAX_AGE }).then((sessionCookie) => {
                    setCookies(COOKIE_ID, sessionCookie, {maxAge: COOKIE_MAX_AGE});
                });
            });*/
            if (props.afterSignUp) {
                props.afterSignUp(userInfo);
            } else {
                nav('/');
            }
         });
    };

    useEffect(() => {
          function handleResize() {
              setViewPortWidth(window.innerWidth);
          }
          window.addEventListener('resize', handleResize)
        });

    const isSmallViewPort = (viewPortWidth < VIEWPORT_LIMIT);
    let signUpClassName = props.afterSignUp ? "SignUpDialog" : "SignUp";
    const textFieldInputProps = { sx: {width: !isSmallViewPort ? 375: 300 }};
    return (
    <ThemeProvider theme={TEXT_FIELD_THEME}>
        <div className={ signUpClassName }>
        {props.closeAction && (<div className="CloseButton" onClick={() => { props.closeAction(); }}><Button style={{color: '#F85804'}}><CloseIcon/></Button></div>) }
            <div className="SignUpContainer">
                <div className="SignUpLogo">
                    <LogoTextLink />
                </div>
                { !props.afterSignUp && (<div className="SignUpMessage">
                    Welcome to <span className="OrangeText">TUXRydes</span>
                </div>) }
                <div className="SignUpControls">
                    {error && (<Typography variant="h6" color={'red'} sx={{textAlign: 'center'}}>Login failed! <br/>{error}</Typography>)}
                    <div>
                        <div className="SignUpTextField">
                            <TUXTextField label="First Name" value={firstName} onChange={onFirstNameChange} InputProps={textFieldInputProps}/>
                        </div>
                        <SpacerSmall/>
                        <div className="SignUpTextField">
                            <TUXTextField label="Last Name" value={lastName} onChange={onLastNameChange} InputProps={textFieldInputProps}/>
                        </div>
                        <SpacerSmall/>
                        <div className="SignUpTextField">
                            <MuiPhoneNumber variant="outlined" label="Phone Number" defaultCountry={'us'} value={phoneNumber} onChange={onPhoneNumberChange}  InputProps={textFieldInputProps}/>
                        </div>
                    </div>
                    <Spacer/>
                    <div className="SignUpButtonContainer">
                        <TUXActionButton flex={true} onClick={onSignUpGoogle} disabled={processing || !userInfoSet()}><div className="SignUpGoogleText"><GoogleIcon/> Continue with Google</div></TUXActionButton>
                    </div>
                    <div className="SignUpOr">
                    ---- OR ----
                    </div>
                    <div className="SignUpTextField">
                        <TUXTextField label="Email" type="email" value={email} onChange={onEmailChange}  InputProps={textFieldInputProps}/>
                    </div>
                    <SpacerSmall/>
                    <div className="SignUpTextField">
                        <TUXTextField label="Password" type={showPassword? 'text': 'password'} value={password} onChange={onPasswordChange} 
                        InputProps={{
                            ...textFieldInputProps,
                            endAdornment:(
                              <InputAdornment position='end'>
                                <IconButton onClick={toggleShowPassword} onMouseDown={handleMouseDown} edge='end'>
                                  {showPassword? <Visibility className='OrangeText'/>: <VisibilityOff/>}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}/>
                    </div>
                    <SpacerSmall/>
                    <div className="SignUpTextField">
                        <TUXTextField label="Confirm Password" type={showConfirmPassword? 'text': 'password'} value={confirmPassword}  onChange={onConfirmPasswordChange} 
                        InputProps={{
                            ...textFieldInputProps,
                            endAdornment:(
                              <InputAdornment position='end'>
                                <IconButton onClick={toggleShowConfirmPassword} onMouseDown={handleMouseDown} edge='end'>
                                  {showConfirmPassword? <Visibility className='OrangeText'/>: <VisibilityOff/>}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}/>
                    </div>
                    <SpacerSmall/>
                    <div className="SignUpButtonContainer">
                        <TUXActionButton flex={true} onClick={onSignUpEmailPassword} disabled={processing || !userInfoSet() || !emailSignUpInfoSet()}>Sign up with Email</TUXActionButton>
                    </div>
                    <div className="SignUpLinks">{ !props.afterSignUp &&  (<div>Already have an account? <Link to="/signin" className="SignUpLink">Login</Link></div>)}</div>
                </div>
             </div>
        </div>
      </ThemeProvider>
      );
}

export default Signup;