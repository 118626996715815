import './TUXActionButton.css';
import React from 'react';

function TUXActionButton(props) {
    const disabled = props?.disabled || false;
    if (props?.flex) {
        return (
            <div className={!disabled? "TUXActionButtonFlex" : "TUXActionButtonFlexDisabled"}
                  onClick={!disabled ? props.onClick : null}>
                <button>{props.children}</button>
            </div>
        );
    } else {
        return (
            <div className={!disabled? "TUXActionButton" : "TUXActionButtonDisabled"}
                  onClick={!disabled ? props.onClick : null}>
                <button>{props.children}</button>
            </div>
        );
    }
}

export default TUXActionButton;